<template >
    <div class="hcell" style="width: 925px;height: 490px;background-color: #f3f3f3" >
        <div class="htable"  style="height: 490px;">
            <div class="hcell" style="width: 450px;background-color: #ecebf0;overflow: hidden;" >
                <img v-if="param" style="height: 490px;width: 450px;display: block;" :src="param.tb_image"  >
            </div>
            <div class="hcell" style="width: 475px;height: 490px;background-color: #f3f3f3;overflow: hidden;display: flex;align-items: center;"  >
                <div style="padding-left: 50px;padding-right: 50px;text-align: left" v-if="param">
                    <div style="font-size: 24px;font-weight: bold;">{{param.tb_title}}</div>
                    <div v-html="param.tb_content" >
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            param: {
                type: Object,
                required: true,
            },
        },
        name:"cpblock_two",
        data(){
            return {
            }
        },
        created(){

        },
        methods:{

        },
        beforeDestroy(){

        },


    }
</script>

<style lang="css" scoped>


</style>

