<template >
    <div class="hcell" style="width: 450px;height: 490px;background-color: #f3f3f3" >
    <!--<div class="cpblock_one">-->
        <div v-if="param">
            <div style="height: 290px;background-color: #eae9ee;overflow: hidden;">
                <img  class="newimg" :src="param.tb_image"  >
            </div>
            <div>
                <div class="newtitle" :title="param.tb_title"><a  >{{param.tb_title}}</a></div>
                <div class="newnote"><a  >{{param.tb_summary}}</a></div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            param: {
                type: Object,
                required: true,
            },
        },
        name:"cpblock_one",
        data(){
            return {
            }
        },
        created(){

        },
        methods:{

        },
        beforeDestroy(){

        },


    }
</script>

<style lang="css" scoped>
    .newimg{
        height: 290px;
        width: 450px;
        display: block;
    }
    .newtitle{
        height: 25px;
        overflow: hidden;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        margin-top: 25px;
    }
    .newnote{
        font-size: 16px;
        color: rgba(0,0,0,0.8);
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
        line-height: 28px;
        margin-top: 15px;
        height:85px;
        overflow: hidden;
    }

</style>

