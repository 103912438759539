<template>
    <div class="photovoltaic">
        <myhead :param="param" />
        <section>
            <div class="bannerphoto">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>
                    <!--<div class="htable" style="font-size: 18px; color: rgba(255,255,255,0.8);margin-top: 40px;line-height: 32px; justify-content: center;">
                        <div class="hcell" style="width: 1200px;">
                        重庆辉腾能源股份有限公司（证券简称：辉腾能源，证券代码：872947）着眼全球清洁能源可持续发展，专注低日照弱光环境智能光伏
                        设备研发生产，提供从光伏产品到光伏应用的整体解决方案，不断开拓创新，引领光伏产业进步，用太阳能造福全人类。
                            </div>
                    </div>
                    <div class="htable" style="font-size: 18px; color: rgba(255,255,255,0.8);margin-top: 40px; justify-content: center;line-height: 32px;">
                        <span class="hcell" style="border: 1px solid rgba(255,255,255,0.8);padding: 20px;">
                            铸平台共创价值 用科技改变生活
                        </span>
                    </div>-->
                </div>
        </section>
        <section style="background-color: #f3f3f3">
            <div class="htable" style="width:1100px;margin-left: calc((100% - 1100px) / 2);height: 64px;justify-content:space-around;align-items:center;font-size: 16px;">
                <div class="hcell" style="width:160px;border-right: 1px solid #020202;font-weight: bold;">能源产品</div>
                <div class="hcell" style="width:160px;"><span class="mgd" @click="goitem('inverter')">光伏逆变器</span></div>
                <div class="hcell" style="width:160px;"><span class="mgd" @click="goitem('matching')">配套产品</span></div>
                <div class="hcell" style="width:160px;"><span class="mgd" @click="goitem('scheme')">应用方案</span></div>
                <div class="hcell" style="width:160px;"><span class="mgd" @click="goitem('storagepower')">光储电源</span></div>
                <div class="hcell" style="width:160px;"><span class="mgd" @click="goitem('lithiumionbattery')">锂离子电池</span></div>
                <div class="hcell" style="width:160px;"><span class="mgd" @click="goitem('otherenergy')">其他能源产品</span></div>
            </div>
        </section>
        <section style="margin-top: 90px;" id="inverter" v-if="inverter && inverter.length>0 ">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">光伏</span>逆变器</div>
                </div>
                <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                    <div class="htable" style="justify-content: space-between;">
                        <cpblock_two :param="inverter[0]" />
                        <cpblock_one :param="inverter[1]"  />
                    </div>
                    <div style="height: 45px;"></div>
                </div>
                <div class="hcell" style="width: 130px;"></div>
            </div>
        </section>

        <section style="margin-top: 45px;" id="matching" v-if="matching && matching.length>0 ">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">配套</span>产品</div>
                </div>
                <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                    <div class="htable" style="justify-content: space-between;">
                        <cpblock_one :param="matching[0]"  />
                        <cpblock_one :param="matching[1]"  />
                        <cpblock_one :param="matching[2]"  />
                    </div>
                    <div style="height: 45px;"></div>
                </div>
                <div class="hcell" style="width: 130px;"></div>

            </div>
        </section>
        <section style="margin-top: 45px;" id="scheme"  v-if="scheme && scheme.length>0 ">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">应用</span>方案</div>
                </div>
                <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                    <div class="htable" style="justify-content: space-between;">
                        <scheme_one :param="scheme[0]"  />
                        <scheme_one :param="scheme[1]"  />
                    </div>
                    <div class="htable" style="justify-content: space-between;margin-top: 25px;" v-if="scheme[2]">
                        <scheme_one :param="scheme[2]"  />
                        <scheme_one :param="scheme[3]"  />
                    </div>
                    <div style="height: 45px;"></div>
                </div>
                <div class="hcell" style="width: 130px;"></div>

            </div>
        </section>
        <section style="margin-top: 40px;" id="storagepower" v-if="storagepower && storagepower.length>0 ">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">光储</span>电源</div>
                </div>
                <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                    <div class="htable" style="justify-content: space-between;">
                        <cpblock_two :param="storagepower[0]" />
                        <cpblock_one :param="storagepower[1]"  />


                    </div>
                    <div style="height: 45px;"></div>
                </div>
                <div class="hcell" style="width: 130px;"></div>
            </div>
        </section>
        <section style="margin-top: 45px;" id="lithiumionbattery" v-if="lithiumionbattery && lithiumionbattery.length >0 ">
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">锂离</span>子电池</div>
                </div>
                <div class="hcell" style="width: 1400px;border-bottom: 1px solid #d7000f;">
                    <div class="htable" style="justify-content: space-between;">
                        <cpblock_one :param="lithiumionbattery[0]"  />
                        <cpblock_one :param="lithiumionbattery[1]"  />
                        <cpblock_one :param="lithiumionbattery[2]"  />

                    </div>
                    <div style="height: 45px;"></div>
                </div>
                <div class="hcell" style="width: 130px;"></div>

            </div>
        </section>

        <section style="margin-top: 45px;" id="otherenergy" v-if="otherenergy && otherenergy.length>0 " >
            <div class="body1660 htable">
                <div class="hcell" style="width: 130px;text-align: left;">
                    <div><span class="btLine">其他</span>能源产品</div>
                </div>
                <div class="hcell" style="width: 1400px;">
                    <div class="htable" style="justify-content: space-between;">
                        <cpblock_one :param="otherenergy[0]"  />
                        <cpblock_one :param="otherenergy[1]"  />
                        <cpblock_one :param="otherenergy[2]"  />
                    </div>
                </div>
                <div class="hcell" style="width: 130px;"></div>

            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>
<script>
    import myhead  from  '../components/myhead.vue'
    import { listTopicBases } from "../hts/api.js"
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    import scheme_one  from  '../components/scheme_one.vue'
    import mydata from '../static/data/photovoltaic.json'
    const param = {
        pageindex: 1,
        rows: 6,
        page: 1,
    }
    export default {
        name: "photovoltaic",

        components: {
            myhead,
            cpblock_two,
            cpblock_one,
            scheme_one,

        },
        data() {
            return {
                param,
                inverter:mydata.inverter,
                matching:mydata.matching,
                scheme:mydata.scheme,
                storagepower:mydata.storagepower,
                lithiumionbattery:mydata.lithiumionbattery,
                otherenergy:mydata.otherenergy,

            }
        },
        mounted() {
            // this.getList()
        },
        methods: {
            getList () {
                // console.log(this.id)
                listTopicBases({type:'photovoltaic: [inverter,matching,scheme,storagepower,lithiumionbattery,otherenergy]', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        this.inverter=resData.data.inverter
                        this.matching=resData.data.matching
                        this.scheme=resData.data.scheme
                        this.storagepower=resData.data.storagepower
                        this.lithiumionbattery=resData.data.lithiumionbattery
                        this.otherenergy=resData.data.otherenergy
                    })
                    .catch()
            },
            goitem(id){
                //document.getElementById(id).scrollIntoView();
                document.getElementById(id).scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            },
        },
    }
</script>
<style lang="css" scoped>
    .bannerphoto{
        background-image: url('~assets/banner2.png');
        height: 600px;
    }
    .mgd {
        cursor:pointer;
        padding: 0 5px;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        /*border-radius: 5px;*/
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 20px;
    }
</style>
